export const convertToSlug = (value) => {
  return value
    .toLowerCase()
    .replace(/-+/g, "")
    .replace(/\s+/g, "-")
    .replace(/[^a-z0-9-]/g, "");
};

export const convertToTwoDecimalCase = (value) => {
  return (Math.round(value * 100) / 100).toFixed(2);
};

export const convertProductPriceToUserPrice = (groups, product) => {
  const configurations = [];

  if (
    !groups ||
    groups === undefined ||
    groups.length <= 0 ||
    !product.configurations ||
    product.configurations === undefined ||
    product.configurations.length <= 0
  )
    return product.price;

  groups.forEach((g) => {
    let groupConfigurations = product.configurations.filter((c) =>
      c.groups.find((cg) => cg._id === g.id)
    );

    if (groupConfigurations.length > 0) {
      configurations.push(
        [...groupConfigurations.sort((c) => c.updatedAt)].shift()
      );
    }
  });

  if (configurations.length === 0) return product.price;

  let config = [...configurations.sort((c) => c.updatedAt)].shift();

  return config.item.value;
};
