import React from "react";

export function Locker(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      {...props}
    >
      <path d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
    </svg>
  );
}

export function WebSite(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      {...props}
    >
      <path d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
    </svg>
  );
}

export function BriefCase(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      {...props}
    >
      <path d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
    </svg>
  );
}

export function Address(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      {...props}
    >
      <path d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7" />
    </svg>
  );
}

export function Country(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      {...props}
    >
      <path d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
      <path d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
    </svg>
  );
}

export function DoubleDownArror(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M19 13l-7 7-7-7m14-8l-7 7-7-7"
      />
    </svg>
  );
}

export function DoubleRightArror(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M13 5l7 7-7 7M5 5l7 7-7 7"
      />
    </svg>
  );
}

export function Logout(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
      />
    </svg>
  );
}

export function User(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      {...props}
    >
      <path d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
    </svg>
  );
}

export function FingerPrint(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      {...props}
    >
      <path d="M12 11c0 3.517-1.009 6.799-2.753 9.571m-3.44-2.04l.054-.09A13.916 13.916 0 008 11a4 4 0 118 0c0 1.017-.07 2.019-.203 3m-2.118 6.844A21.88 21.88 0 0015.171 17m3.839 1.132c.645-2.266.99-4.659.99-7.132A8 8 0 008 4.07M3 15.364c.64-1.319 1-2.8 1-4.364 0-1.457.39-2.823 1.07-4" />
    </svg>
  );
}

export function Email(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      {...props}
    >
      <path d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
    </svg>
  );
}

export function WhatsApp(props) {
  return (
    <svg viewBox="0 0 25 25" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4651 0.157532C5.63805 0.157532 0.0877686 5.70782 0.0877686 12.5274C0.0877686 14.9308 0.771226 17.2365 2.06304 19.2343L0.185405 23.3726C0.0126626 23.7556 0.0727471 24.1988 0.350637 24.5217C0.560932 24.7696 0.861353 24.9047 1.16928 24.9047C1.26692 24.9047 1.35705 24.8897 1.45469 24.8672L6.63695 23.4477C8.42446 24.4015 10.4298 24.9047 12.4651 24.9047C19.2922 24.9047 24.8425 19.3545 24.8425 12.5274C24.8425 5.70782 19.2922 0.157532 12.4651 0.157532ZM12.4651 22.7417C10.6551 22.7417 8.8826 22.261 7.32041 21.3523C7.15518 21.2546 6.96742 21.202 6.77214 21.202C6.67451 21.202 6.57687 21.2171 6.48674 21.2396L3.09949 22.1709L4.27865 19.5723C4.43637 19.2193 4.39881 18.8062 4.1735 18.4907C2.91173 16.7408 2.24329 14.6829 2.24329 12.5199C2.24329 6.88697 6.82472 2.30554 12.4576 2.30554C18.0905 2.30554 22.6719 6.88697 22.6719 12.5199C22.6794 18.1603 18.098 22.7417 12.4651 22.7417Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2268 14.3825C16.7086 14.0745 16.0326 13.729 15.4243 13.9769C14.9586 14.1722 14.6582 14.9007 14.3503 15.2762C14.1925 15.4715 14.0123 15.5015 13.7719 15.4039C12.0145 14.7054 10.6626 13.5263 9.69373 11.9115C9.5285 11.6637 9.55854 11.4609 9.75381 11.228C10.0467 10.8826 10.4147 10.492 10.4974 10.0264C10.58 9.5607 10.3622 9.01994 10.1669 8.60686C9.91904 8.08113 9.64867 7.32256 9.11542 7.02214C8.62723 6.74425 7.98884 6.90197 7.55323 7.25497C6.80217 7.86332 6.44167 8.82467 6.45669 9.771C6.45669 10.0414 6.49424 10.3118 6.55432 10.5671C6.70454 11.1905 6.99745 11.7763 7.3204 12.3321C7.56825 12.7527 7.83112 13.1582 8.11652 13.5488C9.05533 14.8256 10.227 15.9371 11.5789 16.7633C12.2548 17.1764 12.9833 17.5444 13.7419 17.7922C14.5906 18.0701 15.3417 18.363 16.2579 18.1903C17.2118 18.0101 18.1581 17.4167 18.5411 16.4929C18.6538 16.2225 18.7064 15.9146 18.6463 15.6292C18.5111 15.0284 17.7075 14.6754 17.2268 14.3825Z"
      />
    </svg>
  );
}

export function Facebook(props) {
  return (
    <svg viewBox="0 0 12 25" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.14322 12.429V24.2155C3.14322 24.3868 3.27732 24.5209 3.44868 24.5209H7.82205C7.99341 24.5209 8.12752 24.3868 8.12752 24.2155V12.2353H11.3014C11.4578 12.2353 11.5919 12.1161 11.6068 11.9521L11.9123 8.34617C11.9272 8.16736 11.7856 8.0109 11.6068 8.0109H8.12752V5.44797C8.12752 4.84449 8.61179 4.36022 9.21527 4.36022H11.659C11.8303 4.36022 11.9645 4.22611 11.9645 4.05475V0.448776C11.9645 0.277417 11.8303 0.143311 11.659 0.143311H7.52403C5.10266 0.143311 3.13577 2.11021 3.13577 4.53158V8.0109H0.945358C0.773999 8.0109 0.639893 8.14501 0.639893 8.31637V11.9223C0.639893 12.0937 0.773999 12.2278 0.945358 12.2278H3.13577V12.429H3.14322Z"
      />
    </svg>
  );
}

export function Instagram(props) {
  return (
    <svg viewBox="0 0 25 25" {...props}>
      <path d="M18.9632 4.89282C18.1732 4.89282 17.5327 5.53336 17.5327 6.32336C17.5327 7.11336 18.1732 7.7539 18.9632 7.7539C19.7532 7.7539 20.3937 7.11336 20.3937 6.32336C20.3937 5.53336 19.7532 4.89282 18.9632 4.89282Z" />
      <path d="M17.3975 0.423279H7.73955C3.69703 0.423279 0.423157 3.69715 0.423157 7.73967V17.3976C0.423157 21.4401 3.69703 24.714 7.73955 24.714H17.3975C21.44 24.714 24.7139 21.4401 24.7139 17.3976V7.73967C24.7139 3.69715 21.44 0.423279 17.3975 0.423279ZM22.4222 17.3976C22.4222 20.1733 20.1732 22.4223 17.3975 22.4223H7.73955C4.96388 22.4223 2.71487 20.1733 2.71487 17.3976V7.73967C2.71487 4.964 4.96388 2.71499 7.73955 2.71499H17.3975C20.1732 2.71499 22.4222 4.964 22.4222 7.73967V17.3976V17.3976Z" />
      <path d="M12.6147 6.72913C9.30526 6.72913 6.61499 9.4194 6.61499 12.7289C6.61499 16.0383 9.30526 18.7286 12.6147 18.7286C15.9242 18.7286 18.6144 16.0383 18.6144 12.7289C18.6073 9.4194 15.9171 6.72913 12.6147 6.72913ZM12.6147 16.565C10.4938 16.565 8.77148 14.8426 8.77148 12.7217C8.77148 10.6008 10.4938 8.87849 12.6147 8.87849C14.7356 8.87849 16.458 10.6008 16.458 12.7217C16.458 14.8426 14.7285 16.565 12.6147 16.565Z" />
    </svg>
  );
}

export function BagAlt(props) {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path d="M 6,32l 20,0 c 1.104,0, 2-0.896, 2-2L 28,8 c0-1.104-0.896-2-2-2l-4.010,0 C 21.942,2.678, 19.282,0, 16,0S 10.058,2.678, 10.010,6 L 6,6 C 4.896,6, 4,6.896, 4,8l0,22 C 4,31.104, 4.896,32, 6,32z M 26,8l0,22 L 6,30 L 6,8 L 26,8 z M 16,2c 2.174,0, 3.942,1.786, 3.99,4L 12.010,6 C 12.058,3.786, 13.826,2, 16,2zM 13,12l 6,0 C 19.552,12, 20,11.552, 20,11C 20,10.448, 19.552,10, 19,10l-6,0 C 12.448,10, 12,10.448, 12,11C 12,11.552, 12.448,12, 13,12z "></path>
    </svg>
  );
}

export function Mail(props) {
  return (
    <svg viewBox="0 0 23 15" fill="none" {...props}>
      <rect
        x="1"
        y="1"
        width="21"
        height="13"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        y1="-1"
        x2="11.8816"
        y2="-1"
        transform="matrix(0.841634 0.540048 -0.489293 0.872119 2 2)"
        strokeWidth="2"
      />
      <line
        y1="-1"
        x2="11.0532"
        y2="-1"
        transform="matrix(0.814243 -0.580525 0.529008 0.848617 12 8.41663)"
        strokeWidth="2"
      />
    </svg>
  );
}

export function Bag(props) {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path d="M 6,32l 20,0 c 1.104,0, 2-0.896, 2-2L 28,8 c0-1.104-0.896-2-2-2l-4.010,0 C 21.942,2.678, 19.282,0, 16,0S 10.058,2.678, 10.010,6 L 6,6 C 4.896,6, 4,6.896, 4,8l0,22 C 4,31.104, 4.896,32, 6,32z M 19,12l-6,0 C 12.448,12, 12,11.552, 12,11C 12,10.448, 12.448,10, 13,10l 6,0 C 19.552,10, 20,10.448, 20,11C 20,11.552, 19.552,12, 19,12z M 16,2c 2.174,0, 3.942,1.786, 3.99,4L 12.010,6 C 12.058,3.786, 13.826,2, 16,2z"></path>
    </svg>
  );
}

export function Ham(props) {
  return (
    <svg viewBox="0 0 12 9" {...props}>
      <line x1="-4.37114e-08" y1="0.5" x2="12" y2="0.499999" stroke="#717D91" />
      <line x1="-4.37114e-08" y1="4.5" x2="12" y2="4.5" stroke="#717D91" />
      <line x1="-4.37114e-08" y1="8.5" x2="12" y2="8.5" stroke="#717D91" />
    </svg>
  );
}

export function LeftArrow(props) {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path d="M 4,15.004C 4,15.132, 4.028,15.26, 4.078,15.382c 0.048,0.118, 0.12,0.224, 0.208,0.314 C 4.288,15.7, 4.29,15.704, 4.292,15.708l 6,6c 0.39,0.39, 1.024,0.39, 1.414,0c 0.39-0.39, 0.39-1.024,0-1.414L 7.414,16L 27,16 C 27.552,16, 28,15.552, 28,15C 28,14.448, 27.552,14, 27,14L 7.414,14 l 4.292-4.292c 0.39-0.39, 0.39-1.024,0-1.414 c-0.39-0.39-1.024-0.39-1.414,0l-6,6C 4.29,14.296, 4.288,14.302, 4.286,14.304C 4.198,14.394, 4.126,14.5, 4.078,14.618 C 4.026,14.74, 4,14.87, 4,15l0,0C 4,15.002, 4,15.002, 4,15.004z"></path>
    </svg>
  );
}

export function RightArrow(props) {
  return (
    <svg viewBox="0 0 32 32" {...props}>
      <path d="M 4,15C 4,15.552, 4.448,16, 5,16l 19.586,0 l-4.292,4.292c-0.39,0.39-0.39,1.024,0,1.414 c 0.39,0.39, 1.024,0.39, 1.414,0l 6-6c 0.092-0.092, 0.166-0.202, 0.216-0.324C 27.972,15.26, 28,15.132, 28,15.004c0-0.002,0-0.002,0-0.004 l0,0c0-0.13-0.026-0.26-0.078-0.382c-0.050-0.122-0.124-0.232-0.216-0.324l-6-6c-0.39-0.39-1.024-0.39-1.414,0 c-0.39,0.39-0.39,1.024,0,1.414L 24.586,14L 5,14 C 4.448,14, 4,14.448, 4,15z"></path>
    </svg>
  );
}

export function Search(props) {
  return (
    <svg viewBox="0 0 17 16" {...props}>
      <circle cx="11.0002" cy="6" r="5" fillOpacity="0.3" strokeWidth="2" />
      <line
        x1="6.94779"
        y1="9.41179"
        x2="1.41182"
        y2="14.3349"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ChevronRight(props) {
  return (
    <svg viewBox="0 0 8 16" {...props}>
      <path
        d="M1 1L7 8L1 15"
        stroke="white"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ChevronDown(props) {
  return (
    <svg viewBox="0 0 6 4" {...props}>
      <line x1="0.353553" y1="0.646447" x2="3.35355" y2="3.64645" />
      <line x1="2.64645" y1="3.64645" x2="5.64645" y2="0.646447" />
    </svg>
  );
}
