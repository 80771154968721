import Swal from "sweetalert2";
import axios from "axios";
import jwt from "jsonwebtoken";
import { requestAuthConfig } from "../utils/headers";

import { requestUri, userUri, groupsUri } from "../utils/settings";

import { isBrowser } from "../utils/isBrowser";

import { LOGIN_REQUEST, SIGNUP_REQUEST } from "../utils/constants";
import { navigate } from "gatsby";

export const handleRequest = async (data, type) => {
  let result = null;
  let request = null;
  let uri = null;

  if (!isBrowser) {
    return result;
  }

  switch (type) {
    case LOGIN_REQUEST:
      uri = "/.netlify/functions/login";
      request = {
        email: data.email,
        password: data.password,
      };
      break;

    case SIGNUP_REQUEST:
      uri = "/.netlify/functions/signup";
      request = {
        name: data.fullName,
        email: data.email,
        password: data.password,
        optionFields: [
          { name: "confirmPassword", value: data.passwordConfirm },
          { name: "taxIdentifierNumber", value: data.taxIdentifierNumber },
          { name: "address", value: data.address },
          { name: "companyName", value: data.companyName },
          { name: "companyWebsite", value: data.companyWebsite },
          { name: "country", value: data.country },
          { name: "region", value: data.region },
        ],
      };
      break;

    default:
      break;
  }

  await axios
    .post(uri, request)
    .then((response) => {
      if (response.status !== 201) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });

        return result;
      }

      let token = response.data.access_token;
      let expiresIn = response.data.expires_in;
      let jwtPayload = jwt.decode(token);
      let isActive = jwtPayload.userState === "Active";

      if (!isActive && type === SIGNUP_REQUEST) {
        Swal.fire(
          "Good job!",
          "Your account is on review stage, please check your email.",
          "success"
        );
      } else if (!isActive) {
        Swal.fire(
          "Oops...",
          "Your account is on review stage, please wait for confirmation email.",
          "warning"
        );
      }

      result = { token, expiresIn, jwtPayload, isActive };
    })
    .catch(({ response: errorResponse }) => {
      let errors = "";

      if (errorResponse !== undefined && errorResponse.status === 400) {
        errorResponse.data.errors.forEach((s) => (errors += `<p> ${s} </p>`));
      }

      Swal.fire({
        icon: "error",
        title: "Oops...",
        html: errors !== "" ? errors : "Something went wrong!",
      });

      throw errorResponse;
    });

  return result;
};

export const clientLoginAsync = async () => {
  let result = null;
  let request = {};

  await axios
    .post("/.netlify/functions/client-login", request)
    .then((response) => {
      if (response.status === 201) result = response.data.access_token;
    })
    .catch(({ response: errorResponse }) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        html: "Something went wrong!",
      });
    });

  return result;
};

export const createRequestAsync = async (email, type, token) => {
  let result = null;

  let request = { type: type, email: email };

  await axios
    .post(requestUri, request, requestAuthConfig(token))
    .then((response) => {
      if (response.status !== 201) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });

        return result;
      }

      Swal.fire("Good job!", "Check your email, please.", "success");

      navigate("/");
    })
    .catch(({ response: errorResponse }) => {
      let errors = "";

      if (
        errorResponse !== undefined &&
        (errorResponse.status === 400 || errorResponse.status === 409)
      ) {
        errorResponse.data.errors.forEach((s) => (errors += `<p> ${s} </p>`));
      }

      Swal.fire({
        icon: "error",
        title: "Oops...",
        html: errors !== "" ? errors : "Something went wrong!",
      });
    });

  return result;
};

export const updateRequestByIdAsync = async (id, password, token) => {
  let result = null;

  let request = { value: password };

  await axios
    .patch(`${requestUri}/${id}`, request, requestAuthConfig(token))
    .then((response) => {
      if (response.status !== 204) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });

        return result;
      }

      Swal.fire(
        "Good job!",
        "Password updated successfully, try login again please.",
        "success"
      );
      navigate("/");
    })
    .catch(({ response: errorResponse }) => {
      let errors = "";

      if (
        errorResponse !== undefined &&
        (errorResponse.status === 400 || errorResponse.status === 409)
      ) {
        errorResponse.data.errors.forEach((s) => (errors += `<p> ${s} </p>`));
      }

      Swal.fire({
        icon: "error",
        title: "Oops...",
        html: errors !== "" ? errors : "Something went wrong!",
      });
    });

  return result;
};

export const getUserByIdAsync = async (uuid, token) => {
  let result = null;

  if (!isBrowser) {
    return result;
  }

  await axios
    .get(`${userUri}/${uuid}`, requestAuthConfig(token))
    .then((response) => {
      if (response.status !== 200) {
        return result;
      }
      result = response.data;
    })
    .catch(() => {
      return result;
    });

  return result;
};

export const getUserGroupsAsync = async (uuid, token) => {
  const uri = `${groupsUri}?itemId=${uuid}&status=Active`;
  let result = null;

  if (!isBrowser) {
    return result;
  }

  await axios
    .get(uri, requestAuthConfig(token))
    .then((response) => {
      if (response.status !== 200) {
        return result;
      }
      result = response.data;
    })
    .catch(() => {
      return result;
    });

  return result;
};

export const updateUserByIdAsync = async (id, optionFields, token) => {
  let request = { optionFields: [...optionFields] };

  await axios
    .patch(`${userUri}/${id}`, request, requestAuthConfig(token))
    .then((response) => {
      if (response.status !== 204) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });

        return;
      }

      Swal.fire(
        "Good job!",
        "Personal information updated with success",
        "success"
      );
    })
    .catch(({ response: errorResponse }) => {
      let errors = "";

      if (errorResponse !== undefined && errorResponse.status === 401)
        navigate("/");

      if (
        errorResponse !== undefined &&
        (errorResponse.status === 400 || errorResponse.status === 409)
      ) {
        errorResponse.data.errors.forEach((s) => (errors += `<p> ${s} </p>`));
      }

      Swal.fire({
        icon: "error",
        title: "Oops...",
        html: errors !== "" ? errors : "Something went wrong!",
      });
    });
};
