import { useContext } from "react";
import jwt from "jsonwebtoken";
import { AuthContext } from "../store/providers/auth";
import { navigate } from "gatsby";
import {
  getUserByIdAsync,
  handleRequest,
  getUserGroupsAsync,
} from "../services/auth";
import { getPublicKey, signOptions } from "../utils/settings";
import { LOGIN_REQUEST, SIGNUP_REQUEST } from "../utils/constants";
import { v4 as uuidv4 } from "uuid";
import { convertToTwoDecimalCase } from "../utils/converters";
import { calculateShippingPrice } from "../utils/shipping";

import {
  AUTH_SUCCESS,
  AUTH_FAILED,
  UPDATE_BAG,
  RESET_BAG,
  UPDATE_ADDRESSES,
  LOADING_TRUE,
  LOADING_FALSE,
  GET_USER_INFO,
  GET_USER_GROUP,
} from "../store/actions/types";

import {
  SetAuthContextToLS,
  SetBagContextToLS,
  GetBagContextFromLS,
  RemoveBagFromContextFromLS,
  RemoveContextFromLS,
} from "../utils/secure-local-storage";

import { getAddressesAsync } from "../services/checkout";

const useAuth = () => {
  const [state, dispatcher] = useContext(AuthContext);

  const login = (data) => {
    dispatcher({ type: LOADING_TRUE });
    handleLoginAndSignup(data, LOGIN_REQUEST);
  };

  const signup = (data) => {
    dispatcher({ type: LOADING_TRUE });
    handleLoginAndSignup(data, SIGNUP_REQUEST);
  };

  const validateSession = async (data) => {
    if (!data || data === undefined) return;

    jwt.verify(
      data.token,
      getPublicKey(),
      signOptions,
      async (err, decoded) => {
        if (err) {
          dispatcher({ type: AUTH_FAILED });
          return;
        }

        await getUserGroupsByUserIdAsync(decoded.uuid, data.token);

        dispatcher({
          type: AUTH_SUCCESS,
          payload: data,
        });

        getBagFromSessionAsync(data.email);
        getAddressesByTokenAsync(data.token);
        getUserInformationByIdAsync(decoded.uuid, data.token);

        return;
      }
    );
  };

  const logout = () => {
    RemoveContextFromLS();
    dispatcher({ type: AUTH_FAILED });
    navigate("/platform");
  };

  const handleLoginAndSignup = (data, type) =>
    new Promise(async (resolve, reject) => {
      try {
        let result = await handleRequest(data, type);

        if (!result) return;

        let payload = {
          isExpired: false,
          email: result.jwtPayload.email,
          token: result.token,
          isActive: result.isActive,
        };

        await getUserGroupsByUserIdAsync(result.jwtPayload.uuid, result.token);

        dispatcher({
          type: AUTH_SUCCESS,
          payload,
        });

        SetAuthContextToLS(payload);
        getBagFromSessionAsync(result.jwtPayload.email);
        getAddressesByTokenAsync(result.token);
        getUserInformationByIdAsync(result.jwtPayload.uuid, result.token);

        navigate("/platform");

        resolve(result);
      } catch (error) {
        dispatcher({ type: LOADING_FALSE });

        reject(error);
      }
    });

  const handleAddToBag = (data) => {
    const { product, quantity } = data;

    let item = {
      id: uuidv4(),
      product: product,
      price: Number(convertToTwoDecimalCase(product.price * quantity)),
      quantity,
    };

    let bag = state.bag;

    let itemBag = bag.items.find(
      (item) =>
        item.product._id === product._id &&
        !item.product.properties.some(
          (p) => product.properties.find((_p) => _p._id === p._id) === undefined
        )
    );

    if (itemBag) {
      itemBag.quantity += item.quantity;
    } else {
      bag.items.push(item);
    }

    bag.subTotal += item.price;
    bag.subTotal = Number(convertToTwoDecimalCase(bag.subTotal));
    bag.currency = "EUR";

    bag.shipping = calculateShippingPrice(bag.subTotal);

    bag.total = Number(convertToTwoDecimalCase(bag.subTotal + bag.shipping));

    dispatcher({
      type: UPDATE_BAG,
      bag: bag,
    });

    SetBagContextToLS(bag);
  };

  const getBagFromSessionAsync = async (email) => {
    let bag = GetBagContextFromLS();
    let validBag = bag ? bag.email === email : false;

    if (!validBag) {
      dispatcher({ type: RESET_BAG });
    } else {
      dispatcher({
        type: UPDATE_BAG,
        bag: bag,
      });
    }
  };

  const removeItemFromBag = (itemId) => {
    let bag = state.bag;
    bag.subTotal = 0;
    bag.shipping = 0;
    bag.total = 0;

    let items = state.bag.items.filter((i) => i.id !== itemId);
    bag.items = items;

    for (let item of bag.items) {
      bag.subTotal += Number(convertToTwoDecimalCase(item.price));
    }

    bag.shipping = calculateShippingPrice(bag.subTotal);

    bag.total = Number(convertToTwoDecimalCase(bag.subTotal + bag.shipping));

    dispatcher({
      type: UPDATE_BAG,
      bag: bag,
    });

    SetBagContextToLS(bag);
  };

  const getAddressesByTokenAsync = async (token) => {
    let addresses = await getAddressesAsync(token);

    if (!addresses) {
      logout();
    } else {
      dispatcher({
        type: UPDATE_ADDRESSES,
        addresses: addresses,
      });
    }
  };

  const getUserInformationByIdAsync = async (uuid, token) => {
    let user = await getUserByIdAsync(uuid, token);

    if (user === null || user === undefined) return;

    dispatcher({
      type: GET_USER_INFO,
      uuid: user._id,
      name: user.name,
      attributes: user.optionFields,
    });
  };

  const getUserGroupsByUserIdAsync = async (uuid, token) => {
    let groups = await getUserGroupsAsync(uuid, token);

    if (groups === null || groups === undefined || groups.length < 1) return;

    dispatcher({
      type: GET_USER_GROUP,
      groups: groups.map((g) => {
        return { id: g._id };
      }),
    });
  };

  const resetBag = async () => {
    dispatcher({
      type: RESET_BAG,
    });

    RemoveBagFromContextFromLS();
  };

  return {
    state,
    login,
    logout,
    signup,
    validateSession,
    handleAddToBag,
    removeItemFromBag,
    resetBag,
    getAddressesByTokenAsync,
    getUserInformationByIdAsync,
  };
};

export default useAuth;
