import CryptoJS from "crypto-js";
import { appSecret } from "../utils/settings";

export const SetAuthContextToLS = (data) => {
  let _data = CryptoJS.AES.encrypt(JSON.stringify(data), appSecret).toString();
  localStorage.setItem("data", _data);
};

export const GetAuthContextFromLS = () => {
  let dataFromLS = localStorage.getItem("data");
  if (!dataFromLS) return null;

  let bytes = CryptoJS.AES.decrypt(dataFromLS, appSecret);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

export const SetBagContextToLS = (bag) => {
  let _bag = JSON.stringify(bag);
  localStorage.setItem("bag", _bag);
};

export const GetBagContextFromLS = () => {
  return JSON.parse(localStorage.getItem("bag"));
};

export const RemoveBagFromContextFromLS = () => {
  localStorage.removeItem("bag");
};

export const RemoveContextFromLS = () => {
  localStorage.removeItem("data");
  localStorage.removeItem("bag");
};
