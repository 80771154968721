export const appSecret = process.env.GATSBY_APP_SECRET;
export const clientId = process.env.GATSBY_CLIENT_ID;
export const identityUri = process.env.GATSBY_IDENTITY_AUTH_SERVICE_URI;
export const identityVersion = process.env.GATSBY_IDENTITY_AUTH_SERVICE_VERSION;
export const clientSecret = process.env.GATSBY_CLIENT_SECRET;
export const clientScope = process.env.GATSBY_CLIENT_SCOPE;
export const productUri = process.env.GATSBY_PRODUCT_SERVICE_URI;
export const productVersion = process.env.GATSBY_PRODUCT_SERVICE_VERSION;
export const checkoutUri = process.env.GATSBY_CHECKOUT_SERVICE_URI;
export const checkoutVersion = process.env.GATSBY_CHECKOUT_SERVICE_VERSION;
export const identityMonitoringUri = `${identityUri}management/health`;
export const productMonitoringUri = `${productUri}monitoring`;
export const checkoutMonitoringUri = `${checkoutUri}monitoring`;
export const signupUri = `${identityUri}${identityVersion}/signup/tokens`;
export const loginUri = `${identityUri}${identityVersion}/oauth/tokens`;
export const getProductUri = `${productUri}${productVersion}/products`;
export const checkoutServiceUri = `${checkoutUri}${checkoutVersion}`;
export const requestUri = `${identityUri}${identityVersion}/requests`;
export const userUri = `${identityUri}${identityVersion}/users`;
export const groupsUri = `${identityUri}${identityVersion}/groups`;

export const getPublicKey = () => {
  let publicKey = Buffer.from(process.env.GATSBY_PUBLIC_KEY, "base64");

  return publicKey.toString("utf8");
};

export const signOptions = {
  issuer: "IdentityService",
  subject: "distributed.systems.pt@gmail.com",
  audience: "IdentityAuthService",
  expiresIn: 36600,
  algorithm: "RS256",
};
