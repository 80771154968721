import axios from "axios";
import Swal from "sweetalert2";
import { navigate } from "gatsby";
import { isBrowser } from "../utils/isBrowser";
import { requestAuthConfig } from "../utils/headers";
import { checkoutServiceUri } from "../utils/settings";

export const getAddressesAsync = async (token) => {
  let result = [];

  if (!isBrowser) {
    return result;
  }

  await axios
    .get(`${checkoutServiceUri}/addresses`, requestAuthConfig(token))
    .then((response) => {
      if (response.status !== 200) return result;

      result = response.data;
    })
    .catch(({ response: errorResponse }) => {
      if (errorResponse !== undefined && errorResponse.status === 401)
        result = null;

      return result;
    });

  return result;
};

export const createAddressAsync = async (data, token) => {
  var result = null;

  if (!isBrowser) {
    return result;
  }

  await axios
    .post(`${checkoutServiceUri}/addresses`, data, requestAuthConfig(token))
    .then((response) => {
      if (response.status !== 201) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      }

      Swal.fire("Well done!", "Account information updated.", "success");
      navigate("/platform");
      return;
    })
    .catch(({ response: errorResponse }) => {
      let errors = "";

      if (
        errorResponse !== undefined &&
        (errorResponse.status === 400 || errorResponse.status === 409)
      ) {
        errorResponse.data.errors.forEach((s) => (errors += `<p> ${s} </p>`));
      }

      Swal.fire({
        icon: "error",
        title: "Oops...",
        html: errors !== "" ? errors : "Something went wrong!",
      });
    });

  return result;
};

export const createOrderAsync = async (data, selectedAddressId, token, bag) => {
  let result = null;

  if (!isBrowser) {
    return result;
  }

  let request = {
    addresses: [
      {
        _id: selectedAddressId,
        firstName: data.firstName,
        lastName: data.lastName,
        address: data.address,
        addressComp: data.addressComp,
        country: data.country,
        region: data.region,
        city: data.city,
        zipCode: data.zipCode,
        phone: data.phone,
        isDefault: data.isDefault,
        email: data.email,
        taxIdentifierNumber: data.taxIdentifierNumber,
      },
    ],
    lines: bag.items.map((i) => {
      return {
        product: {
          _id: i.product._id,
          title: i.product.title,
          sku: i.product.sku,
          properties: i.product.properties.map((p) => {
            return { _id: p._id };
          }),
        },
        quantity: i.quantity,
        price: {
          unity: i.product.price,
          total: i.price,
        },
      };
    }),
    price: {
      total: bag.total,
    },
    delivery: {
      expectedAt: data.deliveryDate,
    },
    shipping: {
      price: {
        value: bag.shipping,
      }
    },
    note: data.note,
  };

  await axios
    .post(`${checkoutServiceUri}/orders`, request, requestAuthConfig(token))
    .then((response) => {
      if (response.status !== 201) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          html: "Something went wrong! <br/> Please try again later.",
        });
        return result;
      }

      Swal.fire({
        icon: "success",
        title: "Thank you!",
        html: `Your order code is <b> ${response.data} </b> <br/>
        The order is in process. You will receive your order confirmation in your email`,
      });

      result = response.data;
    })
    .catch(() => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        html: "Something went wrong! <br/> Please login and try again later.",
      });
    });

  return result;
};

export const getOrdersAsync = async (token) => {
  let result = [];

  if (!isBrowser) {
    return result;
  }

  await axios
    .get(`${checkoutServiceUri}/orders`, requestAuthConfig(token))
    .then((response) => {
      if (response.status !== 200) {
        return result;
      }
      result = response.data;
    })
    .catch(({ response: errorResponse }) => {
      if (errorResponse !== undefined && errorResponse.status === 401)
        result = null;

      return result;
    });

  return result;
};
